/* comfortaa-light */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 300;
  src: local(''), url('./comfortaa-light.ttf') format('truetype');
}
/* comfortaa-regular */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('./comfortaa-regular.ttf') format('truetype');
}
/* comfortaa-medium */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 500;
  src: local(''), url('./comfortaa-medium.ttf') format('truetype');
}
/* comfortaa-semi-bold */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('./comfortaa-semi-bold.ttf') format('truetype');
}
/* comfortaa-bold */
@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 700;
  src: local(''), url('./comfortaa-bold.ttf') format('truetype');
}
